<template>
  <div class="p-1 pr-0">
    <!-- Search Input -->
    <div v-if="items.length > 3 || first_node" class="mb-1">
      <div class="relative">
        <div class="absolute inset-y-0 left-0 pl-1 flex items-center pointer-events-none">
          <i class="fa fa-search text-gray-400 text-[11px]"></i>
        </div>
        <input 
          type="text" 
          v-model="search"
          placeholder="Kërko..." 
          class="block w-full pl-5 pr-1 py-0.5 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
    </div>

    <!-- Tree Structure -->
    <ul class="list-none p-0 m-0 space-y-0 max-h-[calc(100vh-200px)] overflow-y-auto text-xs">
      <li 
        v-for="i in filteredItems" 
        :key="i.guid"
        class="relative tree-item m-0 p-0"
      >
        <div class="group hover:bg-gray-50 rounded-sm transition-colors duration-150">
          <div class="flex items-center">
            <button 
              v-if="hasChildren(i)"
              @click.prevent="toggleCollapse(i)"
              class="w-4 h-4 flex items-center justify-center text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <i class="fa text-xs" :class="[i.collapsed ? 'fa-square-plus' : 'fa-square-minus']"></i>
            </button>
            <i v-else class="far fa-circle w-4 h-3 text-gray-400"></i>

            <!-- Item Content -->
            <div 
              class="flex-1 flex items-center ml-0.5 min-h-[20px]"
              :class="{ 'bg-blue-50 rounded-sm': isCurrentItem(i) }"
            >
              <div class="flex items-center justify-between flex-1 gap-0.5">
                <div class="font-medium text-gray-900 truncate uppercase" style="font-size: 0.7rem;">
                  {{ i.name }}
                </div>
                
                <div class="flex items-center gap-0.5 flex-nowrap">
                  <div 
                    v-if="hasChildren(i)"
                    class="inline-flex items-center px-1 py-0.5 rounded text-[10px] font-medium bg-green-100 text-green-800"
                    title="Nënkategori"
                  >
                    {{ i.children.length }}
                  </div>

                  <div 
                    v-if="i.total > 0"
                    class="inline-flex items-center py-0.5 rounded text-[10px] font-medium bg-blue-100 text-blue-800"
                    :title="module?.title + ' të lidhura'"
                  >
                    {{ i.total }}
                  </div>

                  <img 
                    v-if="i.image"
                    :src="i.image" 
                    :alt="i.name"
                    class="w-6 h-6 object-cover rounded ml-0.5" 
                  />

                  <div class="opacity-0 hover:opacity-100 transition-opacity duration-200 flex items-center space-x-0.5 ml-1">
                    <button 
                      v-if="showAddButton(i)"
                      @click.prevent="handleAction(i, 'add')"
                      class="p-0 text-gray-400 hover:text-green-600 focus:outline-none"
                    >
                      <i class="fa fa-plus text-xs"></i>
                    </button>
                    <button 
                      @click.prevent="handleAction(i, 'edit')"
                      class="p-0 text-gray-400 hover:text-blue-600 focus:outline-none"
                    >
                      <i class="fa fa-pencil text-xs"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Nested Tree -->
          <Transition name="slide-fade">
            <Tree 
              v-if="shouldShowChildren(i)"
              :items="i.children" 
              :flat="flat" 
              :levels="levels" 
              :module="module" 
              :first_node="false"
              class="ml-3 mt-0.5" 
            />
          </Transition>
        </div>
      </li>
      
      <li 
        v-if="filteredItems.length === 0"
        class="text-center py-1 text-gray-500 text-xs"
      >
        Nuk ka rezultate për "{{ search }}"
      </li>
    </ul>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useCategoryStore } from '@/stores/category'
import { useAccessoryStore } from '@/stores/accessory'
import CategoriesServices from "@/services/Category"
import AccessoryServices from "@/services/Accessory"

export default {
  name: "Tree",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    flat: Boolean,
    levels: {
      type: Number,
      default: -1
    },
    node_has_children: {
      type: Object,
      default: null
    },
    module: {
      type: Object,
      default: null
    },
    first_node: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: "",
      categories: storeToRefs(useCategoryStore()),
      accessories: storeToRefs(useAccessoryStore())
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => 
        JSON.stringify(item).toLowerCase().includes(this.search.toLowerCase())
      )
    },
    list_items() {
      if (this.flat) {
        return this.toTree(this.items)
      }
      return this.items
    }
  },
  methods: {
    hasChildren(item) {
      return Array.isArray(item.children) && item.children.length > 0
    },
    
    isCurrentItem(item) {
      return item.guid === this.categories.current?.guid || 
             item.guid === this.accessories.current?.guid
    },

    showAddButton(item) {
      return this.levels === -1 || 
        (this.levels > 1 && 
         this.node_has_children?.prop && 
         this.node_has_children?.value && 
         item[this.node_has_children.prop] === this.node_has_children.value)
    },

    shouldShowChildren(item) {
      return this.hasChildren(item) && !item.collapsed
    },

    async toggleCollapse(item) {
      item.collapsed = !item.collapsed
      await this.updateStores(item)
    },

    async updateStores(item) {
      // Update categories store
      this.categories.flat_list = this.categories.flat_list.map(e => 
        e.guid === item.guid ? { ...e, collapsed: item.collapsed } : e
      )
      this.categories.list = await CategoriesServices.getTreeCategories(this.categories.flat_list, "id")

      // Update accessories store
      this.accessories.flat_list = this.accessories.flat_list.map(aa => 
        aa.guid === item.guid ? { ...aa, collapsed: item.collapsed } : aa
      )
      this.accessories.list = await AccessoryServices.getTreeAccessories(this.accessories.flat_list, "id")
    },

    handleAction(item, mode) {
      this.categories.mode = mode
      this.accessories.mode = mode
      this.categories.current = { ...item }
      this.accessories.current = { ...item }
    },

    toTree(dataset) {
      const hashTable = Object.create(null)
      dataset.forEach(aData => {
        hashTable[aData.guid] = { ...aData, children: [] }
      })
      
      const dataTree = []
      dataset.forEach(aData => {
        if (aData.parent_id && hashTable[aData.parent_id]) {
          hashTable[aData.parent_id].children.push(hashTable[aData.guid])
        } else {
          dataTree.push(hashTable[aData.guid])
        }
      })
      return dataTree
    }
  }
}
</script>


<style scoped>
.tree-item {
  position: relative;
}

.tree-item::before {
  content: '';
  position: absolute;
  width: 1px;
  height: calc(100% - 12px);
  background-color: #e5e7eb;
  left: -6px;
  top: 12px;
}

.tree-item::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 1px;
  background-color: #e5e7eb;
  left: -6px;
  top: 16px;
}

.slide-fade-enter-active {
  transition: all 0.15s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.15s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-4px);
  opacity: 0;
}

ul::-webkit-scrollbar {
  width: 2px;
}

ul::-webkit-scrollbar-thumb {
  background-color: rgb(209 213 219);
}
</style>
